html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* animation */

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
/* font */

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

@font-face {
  font-family: 'CoCoSharp';
  font-weight: 200;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'CoCoSharp';
  font-weight: 300;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'CoCoSharp';
  font-weight: 400;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'CoCoSharp';
  font-weight: 700;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'CoCoSharp';
  font-weight: 800;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-Extrabold.otf) format('opentype');
}
@font-face {
  font-family: 'CoCoSharp';
  font-weight: 900;
  src: local('CoCoSharp'), url(./fonts/CocoSharp-Heavy.otf) format('opentype');
}

.cocosharp {
  font-family: 'CoCoSharp'
}